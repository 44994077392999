<template>
  <div class="map">
    <div class="content">
      <div :class="[mobile ? 'title title2' : 'title']"></div>
      <div class="flex">
        <div id="allmap">
          <el-image
            style="width: 100%; height: 100%"
            :src="require('../../assets/image/about/mapImg.png')"
            fit="cover"
            alt=""
          ></el-image>
        </div>
        <div class="footer">
          <div class="phone">
            <img src="../../assets/image/about/phone.png" alt="" />
            <div>
              <p class="t1">联系电话</p>
              <p class="t2">（010）62989128</p>
            </div>
          </div>
          <div class="address">
            <img src="../../assets/image/about/address.png" alt="" />
            <div>
              <p class="t1">办公地址</p>
              <p class="t2">北京市朝阳区望京西路甲50号</p>
              <p class="t2">卷石天地大厦A座809室</p>
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <!-- <iframe frameborder="0" style="border: 0px;" allowfullscreen="" width="100%" height="600" 
        src="https://map.baidu.com/mobile/webapp/place/marker/qt=inf&vt=map&act=read_share&code=131/third_party=uri_api&point=12963248.89%7C4825681.92&title=%E5%8C%97%E4%BA%AC%E5%B8%82%E7%BB%B4%E6%B3%B0%E5%BE%8B%E5%B8%88%E4%BA%8B%E5%8A%A1%E6%89%80&content=%E5%8C%97%E4%BA%AC%E5%B8%82%E6%9C%9D%E9%98%B3%E5%8C%BA%E5%BB%BA%E5%9B%BD%E9%97%A8%E5%A4%96%E5%A4%A7%E8%A1%97%E5%8C%97%E4%BA%AC%E5%B9%BF%E6%92%AD%E5%A4%A7%E5%8E%A6803%E5%AE%A4">
        </iframe> -->
    </div>
  </div>
</template>

<script setup>
import { defineComponent, onBeforeMount, ref, onMounted } from "vue";
import helper from "@/utils/helper";

const mobile = ref(false);
onMounted(() => {
  // if (helper.is_mobile()) {
  //     mobile.value = true;
  // }
  // // 百度地图API功能
  // var map = new BMap.Map('allmap');
  // var poi = new BMap.Point(116.307852,40.05703);
  // map.centerAndZoom(poi, 16);
  // map.enableScrollWheelZoom();
  // var content = '<div style="margin:0;line-height:20px;padding:2px;">' +
  //     '办公地址：北京市朝阳区建国门外大街北京广播大厦803室<br/>联系电话：(010)62989128<br/>' +
  //     '</div>';
  // var marker = new BMap.Marker(poi); //创建marker对象
  // marker.enableDragging(); //marker可拖拽
  // var searchInfoWindow1 = new BMapLib.SearchInfoWindow(map,content, {
  //     title: "北京市维泰律师事务所", //标题
  //     panel: "panel", //检索结果面板
  //     enableAutoPan: true, //自动平移
  //     searchTypes: [
  //         BMAPLIB_TAB_FROM_HERE, //从这里出发
  //         BMAPLIB_TAB_SEARCH, //周边检索
  //         BMAPLIB_TAB_TO_HERE,//到这里去
  //     ]
  // });
  // searchInfoWindow1.open(new BMap.Point(116.449579,39.913327),1000);
  // map.addOverlay(marker); //在地图中添加marker
});
</script>

<style lang="less">
#downloadnativepopupUlink {
  display: none !important;
}
@import url("~@/assets/style/baiduMap.css");
.map {
  padding-top: 20px;
  min-height: calc(100% - 140px);
  height: auto;
  margin-bottom: 40px;
  .content {
    width: 960px;
    margin: 0px auto;
    display: block;
  }
  .flex {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
  .title {
    .boxSize(245px,37px);
    background-image: url("../../assets/image/about/title.png");
    background-size: 100% 100%;
  }
  #allmap {
    margin-top: 40px;
    height: 500px;
    overflow: hidden;
    width: 70%;
  }
  .footer {
    margin-top: 20px;
    width: 30%;
    padding-left: 20px;
    .address {
      margin-top: 20px;
    }
    .phone,
    .address {
      width: 100%;
      display: flex;
      div {
        margin-left: 10px;
        line-height: 20px;
      }
    }
    .t1 {
      .font(#333,16px);
      font-weight: bold;
    }
    .t2 {
      .font(#333,16px);
    }
    img {
      .boxSize(40px,40px);
    }
  }
}
.BMapLib_SearchInfoWindow ul {
  display: flex;
}
tr td:nth-child(1) {
  width: 50px;
  padding: 4px !important;
}
tr td:nth-child(2) input {
  width: 106px;
  height: 26px;
}
tr td:nth-child(1) input {
  width: 160px;
  height: 26px;
}
tr td:nth-child(3) {
  display: flex;
}
@media only screen and (max-width: 850px) {
  .BMapLib_SearchInfoWindow {
    display: none !important;
  }
  .map {
    min-height: 100%;
    // padding-top: .56rem;
    padding-bottom: 0.74rem;
    margin-bottom: 0px !important;

    .content {
      padding: 0px 0.37rem;
      width: 100%;
      .flex {
        display: block;
      }
      .title {
        margin: 0 auto;
        display: none;
      }
      #allmap {
        display: block;
        .boxSize(100%,11.11rem);
      }
      // .nav{
      //     .boxSize(100%,1.85rem);
      //     background: #f7f7f7;
      //     .Flex(space-between,center);
      //     padding-right: .19rem;
      //     padding-left: .56rem;
      //     display: none !important;
      //     img{
      //         .boxSize(.46rem,.46rem);
      //     }
      //     .btn{
      //         .boxSize(2.2rem,auto,.74rem);
      //         .font(#4E6DAB,.3rem);
      //         background: #fff;
      //         border-radius: .46rem;
      //         text-align: center;
      //     }
      //     .btn2{
      //         background: #4E6DAB;
      //         color: white;
      //     }
      // }
      .footer {
        margin-top: 0;
        display: block;
        width: 100%;
        .phone,
        .address {
          margin-top: 0.37rem;
        }
        div {
          width: 100%;
        }
        .t1 {
          font-weight: 500;
        }
        .t2 {

        }
      }
    }
  }
}
</style>