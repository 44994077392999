class helper {
    static is_mobile(): boolean {
        return (
            navigator.userAgent.search(
                /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
            ) >= 0
        );
    }
    static getBase64(url:any, callback:Function){
        let canvas:any = document.createElement('canvas'),
        ctx:any = canvas.getContext('2d'),
        img = new Image();
        img.setAttribute('crossOrigin', 'anonymous，*   ')
        // img.crossOrigin = 'Anonymous';//解决Canvas.toDataURL 图片跨域问题
        img.onload = () => {
          canvas.height = img.height;
          canvas.width = img.width;
          ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
          const ext = img.src.substring(img.src.lastIndexOf(".") + 1).toLowerCase(); // 获取到图片的格式
          const dataURL = canvas.toDataURL("image/" + ext); // 得到base64 编码的 dataURL
          callback && callback(dataURL);
          canvas = null;
        };
        img.src = url;
    }
    
}

export default helper;

